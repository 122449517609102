import React from 'react'

import ClaimTemplate from '@/pages/admin/ClaimTemplate';
import KickoffEmailTemplatePage from '@/pages/admin/KickoffEmailTemplatePage';
import ClaimScheduleIcsEventTemplate from '@/pages/admin/ClaimScheduleIcsEventTemplatePage';
import AllClaimsPage from '@/pages/admin/AllClaimsPage';
import DocumentsPage from '@/pages/admin/DocumentsPage';
import MyClaimsPage from '@/pages/admin/MyClaimsPage';
import FinancialDetailsPage from '@/pages/shared/financial_details/FinancialDetailsPage';
import CompanyPage from '@/pages/admin/CompanyPage';
import CompanyEditPage from '@/pages/admin/CompanyEditPage';
import ReportTemplatePage from '@/pages/admin/ReportTemplatePage';
import TechnicalProofPage from '@/pages/shared/technical_proof/TechnicalProofPage';
import ClaimProjectReportDetailPage from '@/pages/shared/technical_proof/ClaimProjectReportDetailPage';
import NewCompanyPage from '@/pages/admin/kimble/NewCompanyPage';

import NewCompanySelectClaimsPage from './pages/admin/kimble/NewCompanySelectClaimsPage';
import ClientCostTemplatePage from './pages/admin/ClientCostTemplatePage';
import ClaimEditGeneralPage from './pages/shared/claim/claimEditSection/general/ClaimEditGeneralPage';
import ClaimEditInternalInvoiceDetailsPage from './pages/shared/claim/claimEditSection/internalInvoiceDetails/ClaimEditInternalInvoiceDetailsPage';
import CreateClaimGroupReportTemplatePage from './pages/shared/claim_group/CreateClaimGroupReportTemplatePage';
import EditReportTemplatePage from './pages/admin/EditReportTemplatePage';
import EditClaimGroupReportTemplatePage from './pages/shared/claim_group/EditClaimGroupReportTemplatePage';
import RndReportsPage from './pages/shared/report_template/RndReportsPage';
import CreateUserPage from './pages/admin/CreateUserPage';
import TechnicalUploadsPage from './pages/admin/TechnicalUploadsPage';
import ClaimGroupOverviewPage from './pages/shared/claim_group/ClaimGroupOverviewPage';
import ClaimGroupMasterPage from './pages/shared/claim_group/ClaimGroupMasterPage';
import ClaimGroupEditGeneralPage from './pages/shared/claim_group/ClaimGroupEditGeneralPage';
import AddClaimToClaimGroupPage from './pages/shared/claim_group/AddClaimToClaimGroupPage';
import AddClaimGroupToCompanyPage from './pages/shared/company/AddClaimGroupToCompanyPage';
import ClaimGroupClaimSubmissionsPage from './pages/admin/ClaimGroupClaimSubmissionsPage';
import ClaimGroupKimbleInvoicesPage from './pages/admin/ClaimGroupKimbleInvoicesPage';
import AllClaimGroupsPage from './pages/shared/claim_group/AllClaimGroupsPage';
import MyClaimGroupsPage from './pages/admin/MyClaimGroupsPage';
import AllSumPage from './pages/shared/financial_details/AllSumPage';
import ProjectsPage from './pages/shared/ProjectsPage';
import DashboardPage from './pages/shared/DashboardPage';
import RegularRealtimeDashboardPage from './pages/shared/RegularRealtimeDashboardPage';
import CompanyDashboardListPage from './pages/shared/claim_group/CompanyDashboardListPage';
import ProjectOverviewPage from './pages/shared/claim_group/ProjectOverviewPage';
import ReleasesPage from './pages/shared/release_notes/ReleasesPage';
import NotesPage from './pages/shared/release_notes/NotesPage';
import TimeTrackingCompaniesPage from './pages/project_time_tracking/TimeTrackingCompaniesPage';
import CreateClaimProjectReportPage from './pages/project_time_tracking/claim_project_reports/CreateClaimProjectReportPage';
import TimeTrackingClaimGroupsPage from './pages/project_time_tracking/TimeTrackingClaimGroupsPage';
import TimeTrackingProjectsPage from './pages/project_time_tracking/TimeTrackingProjectsPage';
import MyTasksPage from './pages/project_time_tracking/MyTasksPage';
import TimeTrackingTasksPage from './pages/project_time_tracking/TimeTrackingTasksPage';
import TimeTrackingTaskFormPage from './pages/project_time_tracking/TimeTrackingTaskFormPage';
import EditClaimProjectReportPage from './pages/project_time_tracking/claim_project_reports/EditClaimProjectReportPage';
import ProjectReportQuestionnairesPage from './pages/shared/technical_proof/ProjectReportQuestionnairesPage';
import ProjectReportEditQuestionnairePage from './pages/shared/technical_proof/ProjectReportEditQuestionnairePage';
import FaqTemplatePage from './pages/admin/faq/FaqTemplatePage';

import CompanyGroupEditPage from './pages/admin/CompanyGroupEditPage';
import CompanyGroupAddPage from './pages/admin/CompanyGroupAddPage';
import ProjectQuestionnaireTemplatePage from '@rd-web-markets/be/dist/pages/ProjectQuestionnaireTemplatePage';
import MatrixTemplatePage from './pages/admin/MatrixTemplatePage';

import LandingPage from './pages/admin/LandingPage';
import CompanyGroupPage from './pages/admin/CompanyGroupPage';
import { ManagerRoute } from '@components/util/ManagerRoute';

let CreatingApplicationPage;
let SubmitApplicationPage;
let FirstFastCheckPage;
let FurtherRequestsPage;
let BsfzCertificatePage;
let BsfzProjectOverviewPage;
let DataCentrePage;
let EmailNotificationTemplatesPage;
let ClaimGroupDashboardPage;


if (process.env.PUBLIC_URL === '/de') {
  CreatingApplicationPage = React.lazy(() => import('./pages/shared/technical_proof/germany/CreatingApplicationPage'));
  SubmitApplicationPage = React.lazy(() => import('./pages/shared/technical_proof/germany/SubmitApplicationPage'));
  FirstFastCheckPage = React.lazy(() => import('./pages/shared/technical_proof/germany/FirstFastCheckPage'));
  FurtherRequestsPage = React.lazy(() => import('./pages/shared/technical_proof/germany/FurtherRequestsPage'));
  BsfzCertificatePage = React.lazy(() => import('./pages/shared/technical_proof/germany/BsfzCertificatePage'));
  BsfzProjectOverviewPage = React.lazy(() => import('./pages/shared/technical_proof/germany/BsfzProjectOverviewPage'));
} else if(process.env.PUBLIC_URL === '/uk') {
  DataCentrePage = React.lazy(() => import('@rd-web-markets/market/dist/claim_group/data_centre/DataCentrePage'));
} else if(process.env.PUBLIC_URL === '/be') {
  EmailNotificationTemplatesPage = React.lazy(() => import('@rd-web-markets/market/dist/pages/EmailNotificationTemplatesPage'));
  ClaimGroupDashboardPage = React.lazy(() => import('./pages/shared/claim_group/ClaimGroupDashboardPage'));
}

export const useManagerRoutes = (basename) => {
  return [
    ( ['/uk'].includes(process.env.PUBLIC_URL) &&
      <ManagerRoute
        exact
        path={['/manager/dashboards', '/super_consultant/dashboards']}
        basename={basename}
        component={CompanyDashboardListPage}
      />
    ),

    ( ['/uk'].includes(process.env.PUBLIC_URL) &&
      <ManagerRoute
        exact
        path='/manager/dashboard/:company_id'
        basename={basename}
        component={DashboardPage}
      />
    ),

    ( ['/uk'].includes(process.env.PUBLIC_URL) &&
      <ManagerRoute
        exact
        path={['/manager/regular_realtime_dashboard/:company_id', '/super_consultant/regular_realtime_dashboard/:company_id']}
        basename={basename}
        component={RegularRealtimeDashboardPage}
      />
    ),
    ( ['/be'].includes(process.env.PUBLIC_URL) &&
      <ManagerRoute
        exact
        path="/manager/claim_groups/:claimGroupId/claim_group_dashboard"
        basename={basename}
        component={ClaimGroupDashboardPage}
      />
    ),

    (
      ['/uk'].includes(process.env.PUBLIC_URL) &&
      <ManagerRoute
        exact
        path="/manager"
        basename={basename}
        component={LandingPage}
      />
    ),

    (
      ['/uk'].includes(process.env.PUBLIC_URL) &&
      <ManagerRoute
        exact
        path="/manager/claim_groups/:claimGroupId/data_centre/:dataCentreSubPage"
        basename={basename}
        component={DataCentrePage}
      />
    ),

    <ManagerRoute
      exact
      path={['/manager', '/super_consultant']}
      basename={basename}
      component={CompanyPage}
    />,
    ( !['/it', '/de'].includes(process.env.PUBLIC_URL) &&
      <ManagerRoute
        exact
        path={['/manager/my_claims', '/super_consultant/my_claims']}
        basename={basename}
        component={MyClaimsPage}
      />
    ),
    ( !['/it', '/de'].includes(process.env.PUBLIC_URL) &&
      <ManagerRoute
        exact
        path={['/super_consultant/all_claims']}
        basename={basename}
        component={AllClaimsPage}
      />
    ),
    <ManagerRoute
      exact
      path="/super_consultant/all_claim_groups"
      basename={basename}
      component={AllClaimGroupsPage}
    />,
    <ManagerRoute
      exact
      path={['/manager/my_claim_groups', '/super_consultant/my_claim_groups']}
      basename={basename}
      component={MyClaimGroupsPage}
    />,
    <ManagerRoute
      exact
      path={['/manager/projects/all', '/super_consultant/projects/all']}
      basename={basename}
      component={ProjectsPage}
    />,
    <ManagerRoute
      exact
      path={['/manager/projects/my', '/super_consultant/projects/my']}
      basename={basename}
      component={ProjectsPage}
    />,
    <ManagerRoute
      exact
      path={['/manager/companies', '/super_consultant/companies']}
      basename={basename}
      component={CompanyPage}
    />,
    <ManagerRoute
      exact
      path="/manager/claim_template"
      component={ClaimTemplate}
    />,
    <ManagerRoute
      exact
      path="/manager/report_templates"
      component={ReportTemplatePage}
    />,
    <ManagerRoute
      exact
      path="/manager/kickoff_email_template"
      component={KickoffEmailTemplatePage}
    />,
    <ManagerRoute
      exact
      path="/manager/claim_schedule_ics_event_template"
      component={ClaimScheduleIcsEventTemplate}
    />,
    <ManagerRoute
      exact
      path="/manager/client_cost_template"
      component={ClientCostTemplatePage}
    />,
    (
      ['/be'].includes(process.env.PUBLIC_URL) &&
      <ManagerRoute
        exact
        path="/manager/mission_email_notification_templates"
        component={EmailNotificationTemplatesPage}
      />
    ),
    (
      ['/be'].includes(process.env.PUBLIC_URL) &&
      <ManagerRoute
        exact
        path="/manager/matrix_template"
        basename={basename}
        component={MatrixTemplatePage}
      />
    ),
    (
      ['/be'].includes(process.env.PUBLIC_URL) &&
      <ManagerRoute
        exact
        path="/manager/project_questionnaire_template"
        basename={basename}
        component={ProjectQuestionnaireTemplatePage}
      />
    ),
    <ManagerRoute
      exact
      path="/manager/faq_template"
      component={FaqTemplatePage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/companies/add_claim_group/:id_in_provider_system_and_companies_house_number/companies/:company_id',
        '/super_consultant/companies/add_claim_group/:id_in_provider_system_and_companies_house_number/companies/:company_id'
      ]}
      basename={basename}
      component={AddClaimGroupToCompanyPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/add_claim/company/:id_in_provider_system/claim_groups/:claimGroupId',
        '/super_consultant/add_claim/company/:id_in_provider_system/claim_groups/:claimGroupId'
      ]}
      basename={basename}
      component={AddClaimToClaimGroupPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/claim_groups/:claimGroupId/edit/general',
        '/super_consultant/claim_groups/:claimGroupId/edit/general'
      ]}
      basename={basename}
      component={ClaimGroupEditGeneralPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/claims/:claim_id/financial_details/:page',
        '/super_consultant/claims/:claim_id/financial_details/:page'
      ]}
      basename={basename}
      component={FinancialDetailsPage}
    />,
    <ManagerRoute
      exact
      path={['/manager/companies/new', '/super_consultant/companies/new']}
      basename={basename}
      component={NewCompanyPage}
    />,
    <ManagerRoute
      exact
      path={['/manager/company_groups', '/super_consultant/company_groups']}
      basename={basename}
      component={CompanyGroupPage}
    />,
    <ManagerRoute
      exact
      path={['/manager/company_groups/create', '/super_consultant/company_groups/create']}
      basename={basename}
      component={CompanyGroupAddPage}
    />,
    <ManagerRoute
      exact
      path={['/manager/company_groups/:company_group_id', '/super_consultant/company_groups/:company_group_id']}
      basename={basename}
      component={CompanyGroupEditPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/companies/new/:id_in_provider_system_and_companies_house_number',
        '/super_consultant/companies/new/:id_in_provider_system_and_companies_house_number'
      ]}
      basename={basename}
      component={NewCompanySelectClaimsPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/companies/:company_id/:claim_group_id/:type/:page',
        '/super_consultant/companies/:company_id/:claim_group_id/:type/:page'
      ]}
      basename={basename}
      component={AllSumPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/companies/:id/edit',
        '/super_consultant/companies/:id/edit'
      ]}
      basename={basename}
      component={CompanyEditPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/companies/:company_id/master',
        '/super_consultant/companies/:company_id/master'
      ]}
      basename={basename}
      component={ClaimGroupMasterPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/companies/:company_id/:claim_group_step',
        '/super_consultant/companies/:company_id/:claim_group_step'
      ]}
      basename={basename}
      component={ClaimGroupOverviewPage}
    />,

    <ManagerRoute
      exact
      path={[
        '/manager/companies/:company_id/:claim_group_id/master',
        '/super_consultant/companies/:company_id/:claim_group_id/master'
      ]}
      basename={basename}
      component={ClaimGroupMasterPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/companies/:company_id/:step_id/:claim_group_step',
        '/super_consultant/companies/:company_id/:step_id/:claim_group_step'
      ]}
      basename={basename}
      component={ClaimGroupOverviewPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/claim_groups/:claimGroupId/project_overview',
        '/super_consultant/claim_groups/:claimGroupId/project_overview'
      ]}
      basename={basename}
      component={ProjectOverviewPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/claim_groups/:claimGroupId/technical_proof/:page',
        '/super_consultant/claim_groups/:claimGroupId/technical_proof/:page'
      ]}
      basename={basename}
      component={TechnicalProofPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id',
        '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id'
      ]}
      basename={basename}
      component={ClaimProjectReportDetailPage}
    />,
    ( ['/be', '/uk'].includes(process.env.PUBLIC_URL) &&
      <ManagerRoute
        exact
        path="/manager/documents"
        basename={basename}
        component={DocumentsPage}
      />
    ),
    (process.env.PUBLIC_URL === '/it' &&
      <ManagerRoute
        exact
        path='/manager/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/documentation/:documentation_type'
        basename={basename}
        component={ClaimProjectReportDetailPage}
      />
    ),
    (process.env.PUBLIC_URL === '/it' &&
      <ManagerRoute
        exact
        path='/manager/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/technical_analysis/'
        basename={basename}
        component={ClaimProjectReportDetailPage}
      />
    ),
    (process.env.PUBLIC_URL === '/it' &&
      <ManagerRoute
        exact
        path='/manager/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/self_declaration_email/'
        basename={basename}
        component={ClaimProjectReportDetailPage}
      />
    ),

    (process.env.PUBLIC_URL === '/it' &&
      <ManagerRoute
        exact
        path='/manager/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/payment_and_invoices/:direct_purchase_lease'
        basename={basename}
        component={ClaimProjectReportDetailPage}
      />
    ),
    ( process.env.PUBLIC_URL === '/de' &&
      <ManagerRoute
        exact
        path={[
          '/manager/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/first_fast_check',
          '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/first_fast_check'
        ]}
        basename={basename}
        component={FirstFastCheckPage}
      />
    ),
    ( process.env.PUBLIC_URL === '/de' &&
      <ManagerRoute
        exact
        path={[
          '/manager/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/creating_application/:page',
          '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/creating_application/:page'
        ]}
        basename={basename}
        component={CreatingApplicationPage}
      />
    ),
    ( process.env.PUBLIC_URL === '/de' &&
      <ManagerRoute
        exact
        path={[
          '/manager/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/submit_application/:page',
          '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/submit_application/:page'
        ]}
        basename={basename}
        component={SubmitApplicationPage}
      />
    ),
    ( process.env.PUBLIC_URL === '/de' &&
      <ManagerRoute
        exact
        path={[
          '/manager/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/bsfz_project_overview/',
          '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/bsfz_project_overview/'
        ]}
        basename={basename}
        component={BsfzProjectOverviewPage}
      />
    ),
    ( process.env.PUBLIC_URL === '/de' &&
      <ManagerRoute
        exact
        path={[
          '/manager/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/additional_informations/:id',
          '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/additional_informations/:id',
        ]}
        basename={basename}
        component={FurtherRequestsPage}
      />
    ),
    ( process.env.PUBLIC_URL === '/de' &&
      <ManagerRoute
        exact
        path={[
          '/manager/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/bsfz_certificates/:id',
          '/super_consultant/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id/bsfz_certificates/:id',
        ]}
        basename={basename}
        component={BsfzCertificatePage}
      />
    ),
    <ManagerRoute
      exact
      path={['/manager/claim_groups/:claimGroupId/technical_uploads', '/super_consultant/claim_groups/:claimGroupId/technical_uploads']}
      basename={basename}
      component={TechnicalUploadsPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/claim_groups/:claim_group_id/report_templates/create',
        '/super_consultant/claim_groups/:claim_group_id/report_templates/create'
      ]}
      basename={basename}
      component={CreateClaimGroupReportTemplatePage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/report_templates/:id/edit',
        '/super_consultant/report_templates/:id/edit',
      ]}
      basename={basename}
      component={EditReportTemplatePage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/claim_groups/:claim_group_id/report_templates/:id/edit',
        '/super_consultant/claim_groups/:claim_group_id/report_templates/:id/edit'
      ]}
      basename={basename}
      component={EditClaimGroupReportTemplatePage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/claim_groups/:claimGroupId/claim_submissions',
        '/super_consultant/claim_groups/:claimGroupId/claim_submissions'
      ]}
      basename={basename}
      component={ClaimGroupClaimSubmissionsPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/claim_groups/:claimGroupId/kimble_invoices',
        '/super_consultant/claim_groups/:claimGroupId/kimble_invoices'
      ]}
      basename={basename}
      component={ClaimGroupKimbleInvoicesPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/report_templates/:report_template_id/rnd_reports',
        '/super_consultant/report_templates/:report_template_id/rnd_reports'
      ]}
      basename={basename}
      component={RndReportsPage}
    />,

    <ManagerRoute
      exact
      path={[
        '/manager/claims/:claim_id/edit/internal_invoice_detail',
        '/super_consultant/claims/:claim_id/edit/internal_invoice_detail'
      ]}
      basename={basename}
      component={ClaimEditInternalInvoiceDetailsPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/claims/:claim_id/edit/general',
        '/super_consultant/claims/:claim_id/edit/general',
      ]}
      basename={basename}
      component={ClaimEditGeneralPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/users/new',
        '/super_consultant/users/new'
      ]}
      basename={basename}
      component={CreateUserPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/release_notes',
        '/super_consultant/release_notes'
      ]}
      basename={basename}
      component={ReleasesPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/release_notes/:release_id',
        '/super_consultant/release_notes/:release_id'
      ]}
      basename={basename}
      component={NotesPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires',
        '/super_consultant/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires'
      ]}
      basename={basename}
      component={ProjectReportQuestionnairesPage}
    />,
    <ManagerRoute
      exact
      path={[
        '/manager/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires/:id',
        '/super_consultant/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires/:id'
      ]}
      basename={basename}
      component={ProjectReportEditQuestionnairePage}
    />,
    <ManagerRoute
      exact
      path="/manager/list_of_companies"
      basename={basename}
      component={TimeTrackingCompaniesPage}
    />,
    <ManagerRoute
      exact
      path="/manager/time_tracking/companies/:company_id/claim_groups/"
      basename={basename}
      component={TimeTrackingClaimGroupsPage}
    />,
    <ManagerRoute
      exact
      path="/manager/time_tracking/claim_groups/:claim_group_id/claim_project_reports/"
      basename={basename}
      component={TimeTrackingProjectsPage}
    />,
    <ManagerRoute
      exact
      path="/manager/time_tracking/claim_groups/:claim_group_id/claim_project_reports/:claim_project_report_id/tasks"
      basename={basename}
      component={TimeTrackingTasksPage}
    />,
    <ManagerRoute
      exact
      path="/manager/time_tracking/claim_groups/:claimGroupId/claim_project_reports/:claimProjectReportId/create_task"
      basename={basename}
      component={TimeTrackingTaskFormPage}
    />,
    <ManagerRoute
      exact
      path="/manager/time_tracking/claim_groups/:claimGroupId/claim_project_reports/:claimProjectReportId/edit_task/:taskId"
      basename={basename}
      component={TimeTrackingTaskFormPage}
    />,
    <ManagerRoute
      exact
      path="/manager/time_tracking/my_tasks/"
      basename={basename}
      component={MyTasksPage}
    />,
    <ManagerRoute
      exact
      path="/manager/time_tracking/my_tasks/claim_project_reports/:claimProjectReportId/create_task"
      basename={basename}
      component={TimeTrackingTaskFormPage}
    />,
    <ManagerRoute
      exact
      path="/manager/time_tracking/my_tasks/claim_project_reports/:claimProjectReportId/edit_task/:taskId"
      basename={basename}
      component={TimeTrackingTaskFormPage}
    />,
    <ManagerRoute
      exact
      path="/manager/time_tracking/create_project"
      basename={basename}
      component={CreateClaimProjectReportPage}
    />,
    <ManagerRoute
      exact
      path="/manager/time_tracking/claim_groups/:claim_group_id/claim_project_reports/:claim_project_report_id"
      basename={basename}
      component={EditClaimProjectReportPage}
    />
  ].filter(route => !!route)
}
  