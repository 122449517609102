import { createTimezonedDate } from "../util/date";
function generateClaimDatesForFinancialPeriodEndingInDecember(accountingPeriod) {
  const accountingPeriodToYear = new Date(accountingPeriod.to).getFullYear();
  const newClaimDates = [];
  let claimFromDate = createTimezonedDate(accountingPeriod.from);
  let claimToDate = createTimezonedDate(`${claimFromDate.getFullYear()}-12-31`);
  while (claimToDate.getFullYear() <= accountingPeriodToYear) {
    newClaimDates.push({
      from_date: claimFromDate,
      to_date: claimToDate
    });
    claimFromDate = createTimezonedDate(`${claimToDate.getFullYear() + 1}-1-1`);
    claimToDate = createTimezonedDate(`${claimToDate.getFullYear() + 1}-12-31`);
  }
  return newClaimDates;
}
function generateClaimDatesForFinancialPeriodEndingInMarch(accountingPeriod) {
  const accountingPeriodToYear = new Date(accountingPeriod.to).getFullYear();
  const newClaimDates = [];
  let claimFromDate = createTimezonedDate(accountingPeriod.from);
  let claimToDate = createTimezonedDate(`${claimFromDate.getFullYear()}-3-31`);

  // If the start date is after 31/3, then the end date should be next year - e.g. if it starts on 01/04.
  // Start date on 01/03 is possible, and the end date will be 31/03 the same year.
  if (claimFromDate.getMonth() > claimToDate.getMonth()) {
    claimToDate = createTimezonedDate(`${claimToDate.getFullYear() + 1}-3-31`);
  }
  while (claimToDate.getFullYear() <= accountingPeriodToYear) {
    newClaimDates.push({
      from_date: claimFromDate,
      to_date: claimToDate
    });
    claimFromDate = createTimezonedDate(`${claimToDate.getFullYear()}-4-1`);
    claimToDate = createTimezonedDate(`${claimToDate.getFullYear() + 1}-3-31`);
  }
  return newClaimDates;
}

/** 
* Generates the start and end dates for single year claims, based on the provided accounting period
* and financial period end.
* The accounting period is in the form { from: '2010-01-01', to: '2013-12-31' } and comes from a Kimble multi-year Element.
* The financialPeriodEnd is one of ['31/12', '31/3'] and shows the end of the yearly financial period for the company during this period of time.
* The financial period end may change in the middle of this period of time.
* Example cases are shown below.
* 
* This function is only used to generate sample claim dates for the user, that they should be able to edit.
* 
* Financial Period: (dd/mm/yyyy), ends 31/12
* 05/06/2013 - 31/12/2015 => {from: 05/06/2013, to: 31/12/2013}, {from: 01/01/2014, to: 31/12/2014} and so on
*
* Financial Period: (dd/mm/yyyy), ends 31/12
* 31/12/2013 - 31/12/2015 => {from: 01/01/2014, to: 31/12/2014}, {from: 01/01/2015, to: 31/12/2015}
* 
* Financial Period: (dd/mm/yyyy
* 01/01/2013 - 31/03/2015 => {from: 01/01/2013, to: 31/12/2013}, {from: 01/01/2014, to: 31/03/2014}, {from: 01/04/2014, to: 31/03/2015}
* 
* Financial Period: (dd/mm/yyyy
* 01/04/2013 - 31/03/2015 => {from: 01/04/2013, to: 31/03/2014}, {from: 01/04/2014, to: 31/03/2015}
*/
export function generateClaimDates(accountingPeriod) {
  if (accountingPeriod.to instanceof Date) {
    // incase it is passed as a date object should be converted to the expected string
    accountingPeriod.to = `${accountingPeriod.to.getFullYear()}-${accountingPeriod.to.getMonth() + 1}-${accountingPeriod.to.getDate()}`;
  }
  if (accountingPeriod.from instanceof Date) {
    // same as previous one
    accountingPeriod.from = `${accountingPeriod.from.getFullYear()}-${accountingPeriod.from.getMonth() + 1}-${accountingPeriod.from.getDay()}`;
  }

  /// We use replaceAll as a temporary solution otherwise it returns one day less than the actual date
  let accountingPeriodToDate = new Date(accountingPeriod.to?.replaceAll('-', '/'));
  const accountingPeriodToMonth = accountingPeriodToDate.getMonth() + 1;
  const accountingPeriodToDay = accountingPeriodToDate.getDate();
  const accountingPeriodToDayMonth = `${accountingPeriodToDay}/${accountingPeriodToMonth}`;
  let claimDates = [];
  // There may be differences in the financial period end and the accounting period to date, in which
  // case the accounting period to date takes precedence.
  if (accountingPeriodToDayMonth === '31/12') {
    // if financialPeriodEnd === 31/12 or financialPeriodEnd === 31/3 but the accounting period ends on 31/12
    claimDates = generateClaimDatesForFinancialPeriodEndingInDecember(accountingPeriod);
  } else if (accountingPeriodToDayMonth === '31/3') {
    // if financialPeriodEnd === 31/3 or financialPeriodEnd === 31/12 but the accounting period ends on 31/3
    claimDates = generateClaimDatesForFinancialPeriodEndingInMarch(accountingPeriod);
  }

  // remove invalid dates
  for (let i = 0; i < claimDates.length; i++) {
    if (claimDates[i].to_date - claimDates[i].from_date <= 0) {
      claimDates.splice(i, 1);
      i--;
    }
  }
  return claimDates;
}