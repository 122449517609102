import React, { useState } from 'react';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { Overlay, Popover } from 'react-bootstrap';
import { CloudUploadButton } from '@rd-web-markets/shared/dist/util/buttons/CloudUploadButton';
import { useTranslation } from 'react-i18next';
import ProjectImportModal from './ProjectImportModal';

const ProjectImportButtonAndModal = ({ importProjects, importStatus }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showProjectImportModal, setShowProjectImportModal] = useState(false);
  const [target, setTarget] = useState(null);
  const { t } = useTranslation();

  const handleButtonClick = (event) => {
    setTarget(event.target);
    setShowOverlay(!showOverlay);
  };

  const popover = (
    <Popover id='popover-basic'>
      <Popover.Body>
        <div style={{ fontSize: '16px' }}>
          Your import file needs to be an excel file with these fields in this order: Project Name, Start Date, End Date,
          Nature of Project (only use values 'IT' or 'Non IT'), Belspo Code (not mandatory) - Do you wish to continue with the import?
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Button
              variant='info'
              className='me-1'
              size='lg'
              onClick={() => {
                setShowProjectImportModal(true);
                setShowOverlay(false);
              }}
            >
              {t('yes')}
            </Button>
            <Button variant='light' className='ms-1' size='lg' onClick={() => setShowOverlay(false)}>
              {t('no')}
            </Button>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );
  
  return (
    <>
      <ProjectImportModal
        show={showProjectImportModal}
        onHide={() => setShowProjectImportModal(false)}
        importProjects={importProjects}
      />

      <CloudUploadButton
        disabled={importStatus?.import_status === 'pending'}
        className='me-2'
        onClick={handleButtonClick}
        text={t('import_projects')}
      />

      <Overlay
        show={showOverlay}
        target={target}
        placement="bottom"
        container={document.body}
        rootClose
        onHide={() => setShowOverlay(false)}
      >
        {popover}
      </Overlay>
    </>
  );
};

export default ProjectImportButtonAndModal;
