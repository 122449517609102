import { CROSS_MARKET_EMAIL_DYNAMIC_FIELDS } from '@rd-web-markets/shared/dist/util/constants';
export const USER_ACCOUNT_TYPES = ['customer', 'consultant', 'manager', 'admin'];
export const USER_ACCOUNT_TYPES_DISPLAY_NAME = {
  'customer': 'customer',
  'consultant': 'consultant',
  'manager': 'manager',
  'admin': 'admin'
};
export const UTR_TEXT = 'UTR';
export const SPRINT_CLAIM_NAME = 'UK_Sprint_Claim';
export const TEMPLATE_PAGE_TITLE = 'Claim Template Listing';
export const CLAIM_GROUP_ENGAGEMENT_TITLE = 'contacts_email_kick_off_meeting_organization';
export const COMPANY_REQUIRES_COMPANIES_HOUSE_INFO = true;
export const CLAIM_SELECTION_ALLOWS_SLICING_MULTI_YEAR_CLAIMS = false;
export const EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS = [{
  field: 'accounting_period_end_date',
  title: 'Accounting Period End Date',
  editableInModal: true
}, {
  field: 'accounting_period_start_date',
  title: 'Accounting Period Start Date',
  editableInModal: true
}, {
  field: 'total_qualifying_rnd_expenditure',
  title: 'Total Qualifying RND Expenditure'
}];
export const EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS = [{
  field: 'company_overview_rnd_context',
  title: 'Company Overview RND Context'
}, {
  field: 'contacts_to_collaborate_on_rnd',
  title: 'Contacts To Collaborate'
}, {
  field: 'collaborate_pluralize',
  title: 'Collaborate Pluralize'
}];
export const CLAIM_GROUP_DEFAULT_PARAMS = {
  consultants_attributes: [],
  project_methodology: 'project_basis',
  rnd_collaborate_contacts_attributes: [],
  has_overdue_taxes: false,
  has_staff_involved_not_on_payroll: false,
  accounts_provided: false,
  tax_computations_provided: false,
  development_costs_capitalised: false,
  contracted_by_third_party: false,
  received_grants_or_subsidies: false,
  reimburses_staff_expenses: false,
  purchased_company_cars: false,
  has_patents: false
};
export const CLAIM_GROUP_EDIT_FORM_FIELDS = {
  showSicCodesString: false,
  showProjectMehotdology: false,
  showOverdueTaxesSection: true,
  showOverdueTaxesQuestion: true,
  showDoesCompanyReimburseStaffExpenses: true,
  showDoesCompanyPayCarAllowance: true
};
export const EDITOR_CLAIM_PROJECT_FIELDS = [];
export const NEW_COMPANY_NEEDS_COMPANY_NUMBER = true;
export const CLIENT_CONTACT_TYPES = ['Client Sponsor', 'Technical Contact', 'Financial Contact', 'Project Manager', 'Partners'];
export const EXTERNAL_CONTACT_TYPES = ['Auditor'];
export const CLAIM_GROUP_EDIT_PAGE_SHOW_YEAR_END_DROPDOWN = false;
export const CLAIM_GROUP_SHOW_ALL_PROJECT = false;
export const APP_FOOTER_TEXT = 'R&D Tax Credits Portal by Ayming';
export const APP_RELEASE_VERSION = 'Release 1.121.1';
export const LOCALE = 'en-GB';
export const ENGINE_NAME = 'UkSection';
export const EMAIL_TEMPLATE_DYNAMIC_FIELDS = {
  email_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  client_cost_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  claim_schedule_ics_event_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  self_declaration_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS
};
export const SHOW_CLAIM_GROUP_TASKS = true;
export const SHOW_COMPANY_CLAIM_GROUP_EDIT_FORM_FIELDS = true;
export const MARKET_SPECIFIC_RD_OR_4 = 'R&D';
export const MARKET_SPECIFIC_RD_OR_4_MENU = 'R&D Technical';
export const TEMPLATE_HEADER_TITLE = {
  email_template: 'Configure Kick Off Template',
  client_cost_template: 'Configure Client Cost Template',
  claim_schedule_ics_event_template: 'Configure Ics Event Template',
  self_declaration_template: 'Configure Self - Declaration Email Template',
  kick_off: 'Configure Kick Off Template'
};
export const REPORT_SAVE_PARAM = '';
export const LOCAL_LANGUAGES = ['en'];
export const REPORT_TYPE_INCLUDE_SECTION_COLUMN_NAME = {
  technical: 'include_in_technical_report'
};
export const TASK_TITLE = 'claim_group_tasks';
export const CURRENCY_SYMBOL = '£';
export const DASHBOARD_PAGE_SIDE_BAR_MENU_ITEMS = {
  link: 'dashboards',
  text: 'Dashboards'
};
export const SIDEBAR_MENU_ITEMS_CUSTOMER = [{
  text: 'project_overview',
  link: 'project_overview'
}, {
  text: 'engagement_team',
  link: 'engagement_team'
},
// {
//     text: 'technical_narratives',
//     link: 'technical_narratives',
// },
{
  text: 'dashboard',
  link: 'dashboard'
}];
export const SIDEBAR_MENU_ITEMS = [{
  text: 'claim_overview',
  icon: 'summarize',
  link: 'master'
}, {
  text: 'project_overview',
  icon: 'assignment',
  link: 'project_overview'
}, {
  text: 'claim_costs',
  link: 'claim_costs',
  icon: 'currency_pound',
  subMenu: true,
  showLastFourChars: true
}, {
  text: 'claim_settings',
  icon: 'settings',
  isOpen: true,
  subMenu: [{
    text: 'claim_info',
    link: 'edit/general'
  }, {
    text: 'engagement_team',
    link: 'engagement_team'
  }]
}, {
  text: 'emails',
  icon: 'email',
  isOpen: true,
  subMenu: [{
    text: 'intro_email',
    link: 'intro_email'
  }, {
    text: 'claim_schedule',
    link: 'claim_schedule'
  }, {
    text: 'schedule_email',
    link: 'schedule_email'
  }]
}, {
  text: 'documents',
  icon: 'description',
  isOpen: true,
  subMenu: [{
    text: 'r_d_technical',
    link: 'technical_section'
  }, {
    text: 'technical_narratives',
    link: 'technical_narratives'
  }, {
    text: 'report_template',
    link: 'report_template'
  }, {
    text: 'data_centre',
    link: 'data_centre'
  }]
}, {
  text: 'review',
  icon: 'rate_review',
  isOpen: true,
  subMenu: [{
    text: 'manager_technical_review',
    link: 'manager_review'
  }, {
    text: 'client_technical_review',
    link: 'client_review'
  }, {
    text: 'complete_claim',
    link: 'complete_claim'
  }]
}];
export const SIDEBAR_MENU_ITEMS_CONSULTANT = [{
  text: 'claim_overview',
  icon: 'summarize',
  link: 'master'
}, {
  text: 'project_overview',
  icon: 'assignment',
  link: 'project_overview'
}, {
  text: 'claim_costs',
  link: 'claim_costs',
  icon: 'currency_pound',
  subMenu: true,
  showLastFourChars: true
}, {
  text: 'claim_settings',
  icon: 'settings',
  isOpen: true,
  subMenu: [{
    text: 'claim_info',
    link: 'edit/general'
  }, {
    text: 'engagement_team',
    link: 'engagement_team'
  }]
}, {
  text: 'emails',
  icon: 'email',
  isOpen: true,
  subMenu: [{
    text: 'intro_email',
    link: 'intro_email'
  }, {
    text: 'claim_schedule',
    link: 'claim_schedule'
  }, {
    text: 'schedule_email',
    link: 'schedule_email'
  }]
}, {
  text: 'documents',
  icon: 'description',
  isOpen: true,
  subMenu: [{
    text: 'r_d_technical',
    link: 'technical_section'
  }, {
    text: 'technical_narratives',
    link: 'technical_narratives'
  }, {
    text: 'report_template',
    link: 'report_template'
  }, {
    text: 'data_centre',
    link: 'data_centre'
  }]
}, {
  text: 'review',
  icon: 'rate_review',
  isOpen: true,
  subMenu: [{
    text: 'manager_technical_review',
    link: 'manager_review'
  }, {
    text: 'client_technical_review',
    link: 'client_review'
  }, {
    text: 'complete_claim',
    link: 'complete_claim'
  }]
}];
export const SIDEBAR_NAV_ADMIN_LINKS = [{
  text: 'Claims',
  link: 'claims',
  icon: 'article',
  subMenu: [{
    text: 'My Claims',
    link: 'my_claim_groups'
  }, {
    text: 'All Claims',
    link: 'all_claim_groups'
  }]
}, {
  text: 'Companies',
  link: 'companies',
  icon: 'apartment'
}, {
  text: 'Documents',
  link: 'documents',
  icon: 'description'
}, {
  text: 'Task Management',
  link: 'task_management',
  icon: 'task_alt',
  subMenu: [{
    text: 'List of Companies',
    link: 'list_of_companies'
  }, {
    text: 'My Tasks',
    link: 'time_tracking/my_tasks'
  }]
}, {
  text: 'Users',
  link: 'users',
  icon: 'group'
}, {
  text: 'Sic codes',
  link: 'sic_codes',
  icon: 'privacy_tip'
}, {
  text: 'Rates',
  link: 'rates',
  icon: 'trending_up'
}, {
  text: 'Templates',
  link: 'templates',
  icon: 'source',
  subMenu: [{
    text: 'Claim Templates',
    link: 'claim_template'
  }, {
    text: 'R&D Report Template',
    link: 'report_templates?template_type=rnd'
  }, {
    text: 'AIF Report Template',
    link: 'report_templates?template_type=aif'
  }, {
    text: 'Kick Off Email Template',
    link: 'kickoff_email_template'
  }, {
    text: 'Claim Schedule Ics Event Template',
    link: 'claim_schedule_ics_event_template'
  }, {
    text: 'Client Cost Template',
    link: 'client_cost_template'
  }, {
    text: 'Questionnaires',
    link: 'questionnaires'
  }, {
    text: 'faq',
    link: 'faq_template'
  }]
}, {
  text: 'Translations',
  link: 'translations',
  icon: 'language'
}];
export const SIDEBAR_NAV_CLIENT_LINKS = [{
  text: 'All Claims',
  link: ''
}, {
  text: 'Dashboard',
  link: 'dashboards'
}];
export const SIDEBAR_NAV_ACCOUNTANT_LINKS = [{
  text: 'All Claims',
  link: ''
}];
export const SIDEBAR_NAV_CONSULTANT_LINKS = [{
  text: 'Companies',
  icon: 'apartment',
  link: 'companies'
}, {
  text: 'Claims',
  icon: 'article',
  link: 'my_claims'
}, {
  text: 'Task Management',
  icon: 'task_alt',
  link: 'task_management',
  subMenu: [{
    text: 'List of Companies',
    link: 'list_of_companies'
  }, {
    text: 'My Tasks',
    link: 'time_tracking/my_tasks'
  }]
}, {
  text: 'Documents',
  icon: 'description',
  link: 'documents'
}];
export const SIDEBAR_CLAIM_COSTS_LINKS = [{
  text: 'Upload/Download Cost Template',
  link: 'financial_details/upload',
  icon: 'cloud_upload'
}, {
  text: 'Cost Categories',
  link: 'financial_details/general',
  icon: 'currency_pound'
}, {
  text: 'Client Cost Email',
  link: 'financial_details/email',
  icon: 'email'
}, {
  text: 'Edit Claim',
  link: 'edit/general',
  icon: 'settings'
}, {
  text: 'Manager Cost Review',
  link: 'financial_details/manager_review',
  icon: 'search'
}];
export const SIDEBAR_CLAIM_COSTS_LINKS_CUSTOMER = [{
  text: 'Upload/Download Cost Template',
  link: 'financial_details/upload'
}, {
  text: 'Cost Categories',
  link: 'financial_details/general'
}];
export const SIDEBAR_CLAIM_COSTS_LINKS_ACCOUNTANT = [{
  text: 'Cost Categories',
  link: 'financial_details/general'
}];
export const SIDEBAR_TECHNICAL_SECTION_LINKS = [{
  text: 'company_info',
  link: 'company_info'
}, {
  text: 'project_reports',
  link: 'project_reports',
  subMenu: true
}, {
  text: 'templates',
  link: 'upload_template',
  subMenu: [{
    text: 'upload_download_project_template',
    link: 'upload_template'
  }, {
    text: 'upload_download_technical_narrative_template',
    link: 'upload_narrative_template'
  }, {
    text: 'upload_download_rnd_report',
    link: 'upload_download_rnd_report'
  }]
}, {
  text: 'documents',
  link: 'technical_uploads'
}];
export const SIDEBAR_CLAIM_OVERVIEW_LINKS_CUSTOMER = [{
  text: 'Claim Cost',
  link: 'claim_cost',
  subMenu: true
}, {
  text: 'R&D Technical',
  link: 'technical_section'
}];
export const SIDEBAR_NAV_RND_REPORT_PAGE_CLIENT_LINKS = {
  link: 'companies',
  text: 'Companies'
};
export const CURRENCY_FORMATTER = new Intl.NumberFormat(LOCALE);
export const PERCENTAGE_FORMATTER = new Intl.NumberFormat(LOCALE, {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
});
export const ENGAGEMENT_TEAM_ROLES = [{
  value: 'client_sponsor'
}, {
  value: 'financial_contact'
}, {
  value: 'technical_contact'
}, {
  value: 'project_manager'
}, {
  value: 'partners'
}];
export const CLIENT_RND_EDIT_DYNAMIC_FIELD = true;
export const CLIENT_RND_SELECT_PROJECT_POPOVER = true;
export const SUPPORT_EMAIL_ADDRESS = 'uk.support.advance@ayming.com ';
export const ATTACHMENT_OPTIONS = {
  attachCostTemplate: true,
  technicalTemplateField: false,
  includeTechnicalUploadField: false,
  includeCostTemplateLink: false
};
export const TEMPLATE_SELECT_MODEL_NAME = 'REPLACE_IF_NEEDED'; //so the build would not fail other wise not needed yet
export const PROJECT_FIELD_MODEL_NAME = 'REPLACE_IF_NEEDED'; //so the build would not fail other wise not needed yet
export const SHOULD_CREATE_REPORT = false; //so the build would not fail other wise not needed yet

export const modifyReportTemplateCategoriesForProject = [0, 0]; //at index 0, remove no element
export const CONSULTANT_ROLE_NAME = 'consultant';
export const SHOW_DEPARTMENT_FOR_USER_FORM = false;
export const SHOULD_PROJECT_POPOVER_EXCLUDE_OTHER_PROJECTS = false;
export const TINYMCE_MARKET_REPORT_MODULES = ['styles_formatter', 'footnotes', 'track_changes', 'comments', 'resize_image_when_added'];
export const TINYMCE_MARKET_EMAIL_MODULES = [
  // 'styles_formatter',
  // 'resize_image_when_added',
];
export const TINYMCE_MARKET_OTHER_MODULES = ['styles_formatter', 'footnotes', 'track_changes', 'comments', 'resize_image_when_added'];
export const SIDEBAR_NAV_SUPER_CONSULTANT_LINKS = [];
export const SHOW_COST_MENU_IN_MASTER = false;
export const SHOW_DOWNLOAD_TEMPLATE_BUTTON = true;
export const SHOW_SIDE_BAR_IN_CLAIM_GROUP_OVERVIEW = true;
export const FINANCIAL_SECTION_GENERAL_LINK = 'general';
export const SHOULD_AUTOSAVE = false;
export const SHOULD_PRESET_COMPANY_CLAIMGROUP_FORM_FILEDS = false;
export const SHOULD_SHOW_ADD_USER_BUTTON_FOR_ADMINS = true;
export const SHOULD_SHOW_ADMIN_OPTION_FOR_CONSULTANTS = false;
export const IS_PROJECT_DATE_SHORT_FORMAT = true;
export const SHOULD_SHOW_DATE_IN_SUMMARY_SECTION = true;
export const RATES_SCHEME = {
  'R&D SME Scheme': ['enhanced_deduction', 'payable_credit'],
  'R&D Intensive SME (Loss-making)': ['intensity_threshold', 'payable_credit'],
  'R&D Expenditure Credit (RDEC) Scheme': ['payable_credit'],
  'Corporation Tax Rates': ['rate'],
  'NI Thresholds': ['threshold'],
  'NI Rates': ['rate']
};
export const SHOULD_ADD_REPORT_TEMPLATE_SEARCH = true;
export const SHOULD_SHOW_TEMPLATE_TITLE = true;
export const SHOW_SEND_CLAIM_BUTTON = true;
export const PROJECT_REPORT_DATE_PICKER_TYPE = 'month';
export const SHOW_CLAIM_GROUP_PROJECT_ELSTER = false;
export const SHOW_PROJECT_TEMPLATE_SELECT = false;
export const SHOW_IT_TYPE_SELECT = false;
export const SHOW_PROJECT_GROUPING_TAB_ON_PROJECT_OVERVIEW_PAGE = true;
export const SHOW_MARKET_PROGRAM_LIST = false;
export const PROJECT_GROUP_RPOGRAM_TYPE_NAME = 'program_commercial_project';
export const PROJECT_OVERVIEW_TABLE = {
  headers: [{
    text: 'subsidiary_name',
    filterableByText: true,
    filterBy: 'subsidiary_company_name'
  }, {
    text: 'business_unit',
    filterableByText: true,
    filterBy: 'business_unit'
  }, {
    text: 'name',
    sortable: true,
    orderBy: 'project_name',
    filterableByText: true,
    filterBy: 'project_name'
  }, {
    text: 'project_category',
    sortable: true,
    orderBy: 'category',
    filterableBySelect: true,
    filterBy: 'project_category',
    filterOptions: ['rnd', 'non_rnd', 'tbc']
  }, {
    text: 'total_cost',
    sortable: true,
    orderBy: 'total_cost'
  }, {
    text: 'total_qe',
    sortable: true,
    orderBy: 'total_qe'
  }, {
    text: 'documents'
  }, {
    text: 'assessment_status',
    sortable: true,
    orderBy: 'assessment',
    filterableBySelect: true,
    filterBy: 'assessment_status',
    filterOptions: ['not_started', 'in_progress', 'completed']
  }, {
    text: 'cost',
    sortable: true,
    orderBy: 'total_cost',
    filterableBySelect: true,
    filterBy: 'cost',
    filterOptions: ['not_started', 'in_progress']
  }, {
    text: 'project_status',
    sortable: true,
    orderBy: 'completion_status',
    filterableBySelect: true,
    filterBy: 'project_status',
    filterOptions: ['unstarted', 'begun_tech_interview_scheduled', 'begun_tech_interview_completed', 'begun_in_manager_qa', 'begun_manager_qa_completed', 'finished']
  }, {
    text: ''
  }, {
    text: ''
  }],
  columns: ['subsidiary_company_name', 'bussiness_units', 'project_name', 'category', 'total_cost', 'total_qe', 'documents', 'assessment_status', 'cost', 'completion_status', 'deleteButton']
};
export const DASHBOARD_TYPES = ['cost_category_breakdown', 'business_unit_intensity_breakdown'];
export const ADMIN_COMMON_ACTIONS = [{
  text: 'add_company_action',
  link: 'companies/new'
}, {
  text: 'add_user_action',
  link: 'users/new'
}];
export const CONSULTANT_COMMON_ACTIONS = [{
  text: 'add_company_action',
  link: 'companies/new'
}];
export const SHOW_QUESTIONNAIRES_SELECT_SEPARATELY = false;
export const SHOW_REGULAR_REALTIME_DASHBOARD = true;
export const SHOW_ADD_CLAIM_GROUP_BTN_ON_MASTER_PAGE = true;
export const SHOULD_SHOW_ADD_CLAIM_BUTTON_IN_CLAIM_GROUP_SIDEBAR = true;
export const SHOULD_SHOW_ADD_COMPANY_BUTTON = true;
export const SHOULD_SHOW_ADD_USERS_TO_COMPANY = true;
export const ADD_NEW_USER_TO_COMPANY = true;
export const ACCOUNT_TYPES_THAT_CAN_ADD_USER_TO_COMPANY = ['customer'];
export const SHOULD_SHOW_CREATE_PROJECTS_PAGE_FOR_CUSTOMER = false;
export const SHOW_EDIT_BUTTON_FOR_RESTRICTED_FOLDER = true;
export const FILE_MANAGEMENT_COLUMN_ORDER = ['folderIcon', 'createdAt', 'name', 'notes', 'uploadedBy', 'editButton'];
export const PROJECT_OVERVIEW_ALL_CONTACTS_MAX_AMOUNT_OF_PROJECT_BADGES = 2;
export const SHOW_COMPANY_GROUPS_HEADER = true;
export const SHOW_ADD_PROJECTS_BUTTON_ON_MASTER_PAGE = true;
export const SHOW_IMPORT_PROJECT_AT_PROJECT_OVERVIEW = false;
export const SHOW_SEND_QUESTIONNAIRE_BUTTON_ON_PROJECT_OVERVIEW_PAGE = true;
export const SHOW_PROJECT_QUESTIONNAIRE_BUTTON_ON_PROJECT_OVERVIEW_PAGE = false;
export const SHOW_QUESTIONNAIRES_TAB_IN_PROJECT_OVERVIEW = false;
export const SHOW_FAQ_ENTRY_HEADER = false;
export const SHOW_CLAIM_GROUPS_SELECTOR = true;
export const UPLOAD_COST_TEMPLATE_PAGE_TITLE = 'Upload Cost Template';
export const UPLOAD_COST_TEMPLATE_BUTTON_NAME = 'Upload Cost Template';
export const DOWNLOAD_COST_TEMPLATE_BUTTON_NAME = 'Download Template';
export const FINANCIAL_DATA_PAGE_NAME = 'Cost Categories';
export const SHOW_CLAIM_DELETE_BUTTON = true;
export const SHOW_ADD_FINANCIAL_YEAR = false;