import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback } from 'react';
import { buildService } from '@rd-web-markets/shared/dist/services/service';

const api = buildService('/claim_groups/{id}/ongoing_template_imports', { only: [ 'all', 'base' ]}, { only: ['postFormData'] })

const useFetchLastImportStatusWithInterval = ({ claimGroupId, setLoading }) => {
  const {
    interval,
    setInterval,
    stateVar: lastImportStatus,
    setStateVar: setLastImportStatus
  } = serviceMethods.useFetchWithInterval({
    api,
    callback: useCallback(async () => await api.all(claimGroupId), [claimGroupId]),
    setLoading
  })

  return { lastImportStatus, setLastImportStatus, interval, setInterval }
}


const ongoingTemplateImportService = {
  useFetchLastImportStatusWithInterval,
  create(claimGroupId, formData, type, count) {
    return fetch(`/api/claim_groups/${claimGroupId}/ongoing_template_imports?type=${type}&documentsCount=${count}`, {
      ...request.post,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData,
    });
  },
  delete(claimGroupId, id) { 
    return fetch(`/api/claim_groups/${claimGroupId}/ongoing_template_imports/${id}`, request.delete);
  }
}

export default ongoingTemplateImportService;