/**
 * converts a given string to a translation key
 * @param {String} key - to convert
 * @param {Object} options - an object with specific parameters
 * @param {Boolean} options.dot
 * @param {Boolean} options.dash
 * @param {Boolean} options.bracket
 * @param {Boolean} options.slash
*/

export const makeKey = function (key) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  let convertedKey = key?.toLowerCase().trim()?.replaceAll(/[^a-z0-9]/g, '_');

  //remove plenty underscores
  convertedKey = convertedKey?.replaceAll(/__+/g, '_');

  //remove plenty underscore at the end
  convertedKey = convertedKey?.replace(/_+$/, '');
  return convertedKey;
};