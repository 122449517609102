import { useCallback } from 'react';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '../service_methods';
const api = buildService('/lists/claims', {
  only: ['all']
});
export const claimListService = {
  useFetchAll(_ref) {
    let {
      setLoading,
      ids,
      initialQueryParams
    } = _ref;
    const [claims, setClaims, fetchClaims, pages] = serviceMethods.useFetch({
      callback: useCallback(async queryParams => await api.all({
        ...queryParams,
        'ids[]': ids
      }), [ids]),
      setLoading,
      paginationResourceField: 'claims',
      initialQueryParams: initialQueryParams || {}
    });
    return [claims, setClaims, fetchClaims, pages];
  }
};