import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Card, Row } from 'react-bootstrap';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch, useSelector } from 'react-redux';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import ClaimGroupBreadcrumbLinks from '@rd-web-markets/shared/dist/claim_group/edit/ClaimGroupBreadcrumbLinks';
import ClaimGroupEditNavigation from '@rd-web-markets/shared/dist/claim_group/edit/ClaimGroupEditNavigation';
import ClaimGroupEditFooter from '@rd-web-markets/shared/dist/claim_group/edit/ClaimGroupEditFooter';
import { claimTemplateService } from '@services/claim_template.service';
import questionnairesService from '@services/questionnaires.service';
import CompanyClaimGroupEditForm from '@rd-web-markets/shared/dist/company/CompanyClaimGroupEditForm';
import { SPRINT_CLAIM_NAME } from '@rd-web-markets/market/dist/constants';
import { fetchAndSetAdmins, fetchAndSetConsultants } from 'src/effects/user.effects';
import ClaimGroupSidebar from '@rd-web-markets/shared/dist/pages/setup/ClaimGroupSidebar';
import { useGetUserClaimGroupAbilities } from '@rd-web-markets/market/dist/userAbilities';
import { userListService } from '@services/user_list_service';

const ClaimGroupEditGeneralPage = ({handleToaster, accountType}) => {
  const { claimGroupId } = useParams();
  const [claimGroup, setClaimGroup] = useState(null);
  const [claimTemplates, setClaimTemplates] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState(null);
  const [defaultTemplate, setDefaultTemplate] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [consultants, setConsultants] = useState(null);
  const [admins, setAdmins] = useState(null);
  const [managers, setManagers] = userListService.useFetchUsersByType({ userType: 'manager', setLoading })
  const [isUnderReview, setisUnderReview] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const userClaimGroupAbilities = useGetUserClaimGroupAbilities(user, claimGroup?.id)
  const pageAccess = userClaimGroupAbilities.pages.ClaimGroupEditGeneralPage

  const [questionnaires] = questionnairesService.useQuestionnaires();

  const transformClaimGroup = claimGroup => {
    setClaimGroup({
      ...claimGroup,
      consultants_attributes: claimGroup.consultant_roles
    });
  };

  useEffect(() => {
    fetchAndSetConsultants(setConsultants,dispatch, handleError);
    fetchAndSetAdmins(setAdmins, dispatch, handleError);
    const getData = async () => {
      try {
        const claimGroup = await claimGroupService.get(claimGroupId);
        if (process.env.PUBLIC_URL !== '/be') {
          const claimTemplates = await claimTemplateService.get();
          setClaimTemplates(claimTemplates);
          const tasks = [];
          const defaultTemplate = claimTemplates.find(template => template.name === SPRINT_CLAIM_NAME);

          if(defaultTemplate) {
            defaultTemplate.claim_template_categories.forEach(category => {
              category.claim_template_tasks.forEach(task => tasks.push({...task, category_title: category.title, included: true }));
            });
          }
          setDefaultTemplate(defaultTemplate);
          setTasks(tasks);
        }

        transformClaimGroup(claimGroup);
        if (claimGroup?.active_change_set) {
          setisUnderReview(true);
        }
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    getData();
  }, [claimGroupId, dispatch]);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    if(tasks){
      claimGroup.tasksJSON = tasks.filter(task => task.included === true);
    }
    try {
      await claimGroupService.update(claimGroup);
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setClaimGroup({
      ...claimGroup,
      [event.target.name]: value,
    });
  };

  const handleClaimTypeChange = e => {
    const template = claimTemplates.find(template => template.id === +e)
    const newTasks = [];

    template.claim_template_categories.forEach(category => {
      category.claim_template_tasks.forEach(task => newTasks.push({...task, category_title: category.title, included: true }));
    });
    setTasks([...newTasks]);
  };

  const handleRadioButtonChange = event => {
    const value = event.target.value === 'true' ? true : false;
    setClaimGroup({
      ...claimGroup,
      [event.target.name]: value
    });
  };

  const updateTasks = (tasks) => {
    setTasks(tasks);
  };

  if(!claimGroup || ((!claimTemplates || !tasks) && process.env.PUBLIC_URL !== '/be') || !consultants || !admins || !questionnaires) return <Loading />

  return (
    <>
      { isUnderReview && 
        <Alert key='warning' variant='warning'>
          Claim info can not be edited after manager or client review has started  
        </Alert> 
      }
      <ClaimGroupSidebar
        claimGroup={claimGroup}
        company={claimGroup.company}
        claims={claimGroup.claims}
        accountType={accountType}
        highlightedMenuItem='edit/general'
      />
      <ClaimGroupBreadcrumbLinks claimGroup={claimGroup}/>
      <Card>
        <Card.Header>
          <ClaimGroupEditNavigation accountType={accountType} page={'general'} claimGroup={claimGroup}/>
        </Card.Header>
        <Card.Body>
          <CompanyClaimGroupEditForm
            handleClaimGroupChange={handleChange}
            handleClaimTypeChange={handleClaimTypeChange}
            updateTasks={updateTasks}
            claimTemplates={claimTemplates}
            defaultTemplate={defaultTemplate}
            handleRadioButtonChange={handleRadioButtonChange}
            claimGroup={claimGroup}
            setClaimGroup={setClaimGroup}
            tasks={tasks}
            admins={admins}
            managers={managers}
            consultantsAndAdmins={[...consultants, ...managers, ...admins]}
            onUpdateConsultants={transformClaimGroup}
            isUnderReview={isUnderReview}
            questionnaires={questionnaires}
            pageAccess={pageAccess}
            handleToaster={handleToaster}
          />
          <Row className="modal-footer">
            <ClaimGroupEditFooter
              loading={loading}
              handleSubmit={handleSubmit}
              backUrl={`/${accountType}/claim_groups/${claimGroup.id}/project_overview`}
              continueUrl={`/${accountType}/claim_groups/${claimGroup.id}/project_overview`}
              isUnderReview={isUnderReview}
            />
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default ClaimGroupEditGeneralPage
