import { useCallback } from 'react';
import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
const api = buildService('/companies/{id}/claim_groups', {
  only: ['all']
});
const getClaimGroupsService = {
  useFetchAllClaimGroups(companyId, setLoading) {
    const [claimGroups, setClaimGroups, fetchClaimGroups] = serviceMethods.useFetch({
      callback: useCallback(async queryParams => await api.all(companyId, queryParams), [companyId]),
      setLoading
    });
    return [claimGroups, setClaimGroups, fetchClaimGroups];
  },
  all(company_id) {
    console.log('inside getClaimGroupsService - all');
    return fetch(`/api/companies/${company_id}/claim_groups`, request.get);
  }
};
export default getClaimGroupsService;