import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import LoginPage from '@/pages/LoginPage';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { useDispatch } from 'react-redux';
import { getCurrentSession } from '@rd-web-markets/shared/dist/store/features/authSlice';
import { completeLoading } from '@rd-web-markets/shared/dist/store/features/uiSlice';
import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import CostTemplateUploadPage from './pages/CostTemplateUploadPage';
import TechnicalUploadLinkPage from './pages/TechnicalUploadLinkPage';
import { Suspense } from 'react';
import FailedLoginPage from './pages/FailedLoginPage';
import { useAdminRoutes } from './useAdminRoutes';
import { useConsultantRoutes } from './useConsultantRoutes';
import { useClientRoutes } from './useClientRoutes';
import { useManagerRoutes } from './useManagerRoutes';

const App = () => {
  const { isLoading } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const basename = process.env.PUBLIC_URL;
  const adminRoutes = useAdminRoutes(basename)
  const consultantRoutes = useConsultantRoutes(basename)
  const clientRoutes = useClientRoutes(basename)
  const managerRoutes = useManagerRoutes(basename)

  /**
   * Ideally App.js should not rerender often. But just in case, lets cache the results.
   * We set the key here, because its not obvious that it needs to be set in the files, and we should hide this keys warning.
   */
  const adminRoutesWithKeys = useMemo(() => 
    adminRoutes.map((route, index) => React.cloneElement(route, { key: index })), 
    [adminRoutes]
  );

  const consultantRoutesWithKeys = useMemo(() => 
    consultantRoutes.map((route, index) => React.cloneElement(route, { key: index })), 
    [consultantRoutes]
  );

  const clientRoutesWithKeys = useMemo(() => 
    clientRoutes.map((route, index) => React.cloneElement(route, { key: index })), 
    [clientRoutes]
  );

  const managerRoutesWithKeys = useMemo(() => 
    managerRoutes.map((route, index) => React.cloneElement(route, { key: index })), 
    [managerRoutes]
  );

  useEffect(() => {
    fetch('/api/users/current_session', {})
      .then(response => {
        dispatch(getCurrentSession(response));
        dispatch(completeLoading(''));
      }).catch(error =>   dispatch(completeLoading(error.message)));
  });

  /**
   * Never use React.Fragment (<></>) or random pages will stop working.
   */
  return (
    <Router basename={basename}>
      {isLoading ? (
        <Loading />
      ) : (
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/admin" />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/login_failed">
              <FailedLoginPage />
            </Route>
            <Route path="/claim_import_cost_template_infos/:import_id/one_time_link_uploads/:link_id">
              <CostTemplateUploadPage />
            </Route>
            <Route path="/technical_uploads/:technical_upload_id/one_time_link_uploads/:link_id">
              <TechnicalUploadLinkPage />
            </Route>
            
            { adminRoutesWithKeys }
            { consultantRoutesWithKeys }
            { clientRoutesWithKeys }
            { managerRoutesWithKeys }
          </Switch>
        </Suspense>
      )}
    </Router>
  );
};

export default App;