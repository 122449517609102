import React from 'react'

import FinancialDetailsPage from '@/pages/shared/financial_details/FinancialDetailsPage';
import ClaimProjectReportDetailPage from '@/pages/shared/technical_proof/ClaimProjectReportDetailPage';
import { ClientRoute } from '@components/util/ClientRoute';
import ClientRndReportsPage from './pages/client/ClientRndReportsPage';
import ClaimGroupOverviewPage from './pages/shared/claim_group/ClaimGroupOverviewPage';
import ClaimGroupEditGeneralPage from './pages/shared/claim_group/ClaimGroupEditGeneralPage';
import MyClaimGroupsPage from './pages/admin/MyClaimGroupsPage';
import DashboardPage from './pages/shared/DashboardPage';
import ClientDashboardPage from './pages/shared/ClientDashboardPage';
import ClientRegularRealtimeDashboardPage from './pages/shared/ClientRegularRealtimeDashboardPage';
import RegularRealtimeDashboardPage from './pages/shared/RegularRealtimeDashboardPage';
import ClaimGroupDashboardListPage from './pages/shared/claim_group/ClaimGroupDashboardListPage';
import CompanyDashboardListPage from './pages/shared/claim_group/CompanyDashboardListPage';
import ProjectOverviewPage from './pages/shared/claim_group/ProjectOverviewPage';
import ReleasesPage from './pages/shared/release_notes/ReleasesPage';
import NotesPage from './pages/shared/release_notes/NotesPage';
import ProjectReportQuestionnairesPage from './pages/shared/technical_proof/ProjectReportQuestionnairesPage';
import ProjectReportEditQuestionnairePage from './pages/shared/technical_proof/ProjectReportEditQuestionnairePage';
import TechnicalNarrativesPage from '@rd-web-markets/uk/dist/pages/TechnicalNarrativesPage';
import ClientClaimGroupEngagementPage from './pages/shared/claim_group/client/ClientClaimGroupEngagementPage';

let ClientTemplatePage;
let CustomerCreateProjectPage;
// let ClientAllClaimsWithProjectProgressPage;
let ClientWorkflowPage;
let ClientOrganisationalChartPage;
let ClaimGroupDashboardPage;
let ClientHrPage;
let ClientCompanyPage;

if(process.env.PUBLIC_URL === '/de'){
  // ClientAllClaimsWithProjectProgressPage = React.lazy(() => import('@rd-web-markets/market/dist/pages/ClientAllClaimsWithProjectProgressPage'));
} else if(process.env.PUBLIC_URL === '/be') {
  CustomerCreateProjectPage = React.lazy(() => import('@rd-web-markets/market/dist/claim_project_reports/CustomerCreateProjectPage'));
  ClaimGroupDashboardPage = React.lazy(() => import('./pages/shared/claim_group/ClaimGroupDashboardPage'));
  ClientOrganisationalChartPage = React.lazy(() => import('@rd-web-markets/market/dist/pages/ClientOrganisationalChartPage'));
  ClientTemplatePage = React.lazy(() => import('@rd-web-markets/market/dist/pages/ClientTemplatePage'));
  ClientHrPage = React.lazy(() => import('./pages/shared/claim_group/client/ClientHrPage'));
  ClientCompanyPage = React.lazy(() => import('./pages/shared/claim_group/client/ClientCompanyPage'));
  ClientWorkflowPage = React.lazy(() => import('@rd-web-markets/market/dist/pages/ClientWorkflowPage'));
}


export const useClientRoutes = (basename) => {
  return [
    <ClientRoute
      exact
      path="/customer"
      basename={basename}
      component={MyClaimGroupsPage}
    />,
    // (process.env.PUBLIC_URL === '/it' ?
    //   <ClientRoute
    //     exact
    //     path="/customer"
    //     basename={basename}
    //     component={ClientAllClaimsWithProjectProgressPage}
    //   /> :
    //   <ClientRoute
    //     exact
    //     path="/customer"
    //     basename={basename}
    //     component={MyClaimGroupsPage}
    //   />
    // ),
    (process.env.PUBLIC_URL === '/be' &&
      <ClientRoute
        exact
        path="/customer/claim_groups/:claimGroupId/organisational_chart"
        basename={basename}
        component={ClientOrganisationalChartPage}
      />
    ),
    <ClientRoute
      exact
      path="/customer/companies/:company_id/master"
      basename={basename}
      component={MyClaimGroupsPage}
    />,
    ( ['/us', '/ca', '/de', '/uk', '/be'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/companies"
        basename={basename}
        component={MyClaimGroupsPage}
      />
    ),
    <ClientRoute
      exact
      path="/customer/report_template/:report_template_id"
      basename={basename}
      component={ClientRndReportsPage}
    />,
    <ClientRoute
      exact
      path="/customer/claim_groups/:claimGroupId/project_overview"
      basename={basename}
      component={ProjectOverviewPage}
    />,
    (process.env.PUBLIC_URL === '/uk' && (
      <ClientRoute
        exact
        path="/customer/claim_groups/:claimGroupId/technical_narratives"
        basename={basename}
        component={TechnicalNarrativesPage}
      />
    )),
    <ClientRoute
      exact
      path="/customer/claim_groups/:claimGroupId/technical_proof/project_reports/:report_id"
      basename={basename}
      component={ClaimProjectReportDetailPage}
      pageName={'ClaimProjectReportDetailPage'}
    />,
    <ClientRoute
      exact
      path="/customer/claims/:claim_id/financial_details/:page"
      basename={basename}
      component={FinancialDetailsPage}
    />,
    ( ['/be'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/claim_groups/:claimGroupId/templates"
        basename={basename}
        component={ClientTemplatePage}
      />
    ),
    ( ['/be'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/claim_groups/:claimGroupId/edit/general"
        basename={basename}
        component={ClaimGroupEditGeneralPage}
      />
    ),
    ( ['/be'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/claim_groups/:claim_group_id/create_project"
        basename={basename}
        component={CustomerCreateProjectPage}
      />
    ),
    ( ['/uk'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/companies/:company_id/:step_id/:claim_group_step"
        basename={basename}
        component={ClaimGroupOverviewPage}
      />
    ),
    ( ['/it'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/dashboards/"
        basename={basename}
        component={ClaimGroupDashboardListPage}
      />
    ),
    ( ['/uk', '/us'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/dashboards/"
        basename={basename}
        component={CompanyDashboardListPage}
      />
    ),
    ( ['/uk', '/us'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/claim_groups/:claim_group_id/dashboard/:company_id"
        basename={basename}
        component={ClientDashboardPage}
      />
    ),
    ( ['/uk'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/claim_groups/:claim_group_id/regular_realtime_dashboard/:company_id"
        basename={basename}
        component={ClientRegularRealtimeDashboardPage}
      />
    ),
    ( ['/uk'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/regular_realtime_dashboard/:company_id"
        basename={basename}
        component={RegularRealtimeDashboardPage}
      />
    ),
    ( ['/it'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/dashboard/:claim_group_id"
        basename={basename}
        component={DashboardPage}
      />
    ),
    ( ['/be'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/companies/:company_id/:claim_group_id/engagement_team"
        basename={basename}
        component={ClientClaimGroupEngagementPage}
      />
    ),
    ( ['/be'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/companies/:company_id/:claim_group_id/hr_data"
        basename={basename}
        component={ClientHrPage}
      />
    ),
    ( ['/be'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/companies/:company_id/:claim_group_id/company"
        basename={basename}
        component={ClientCompanyPage}
      />
    ),
    <ClientRoute
      exact
      path="/customer/release_notes"
      basename={basename}
      component={ReleasesPage}
    />,
    <ClientRoute
      exact
      path="/customer/release_notes/:release_id"
      basename={basename}
      component={NotesPage}
    />,
    <ClientRoute
      exact
      path="/customer/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires"
      basename={basename}
      component={ProjectReportQuestionnairesPage}
    />,
    <ClientRoute
      exact
      path="/customer/claim_groups/:claim_group_id/technical_proof/project_reports/:report_id/questionnaires/:id"
      basename={basename}
      component={ProjectReportEditQuestionnairePage}
    />,

    ( ['/be'].includes(process.env.PUBLIC_URL) &&
      <ClientRoute
        exact
        path="/customer/claim_groups/:claimGroupId/claim_group_dashboard"
        basename={basename}
        component={ClaimGroupDashboardPage}
      />
    ),
    (process.env.PUBLIC_URL === '/be' &&
      <ClientRoute
        exact
        path="/customer/claim_groups/:claimGroupId/workflow"
        basename={basename}
        component={ClientWorkflowPage}
      />
    )
  ].filter(route => !!route)
}
