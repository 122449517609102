import { useCallback } from 'react';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '../service_methods';
const api = buildService('/lists/claim_groups', {
  only: ['all']
});
export const claimGroupListsService = {
  useFetchAll(_ref) {
    let {
      setLoading,
      ids
    } = _ref;
    const [claimGroups, setClaimGroups, fetchClaimGroups, pages] = serviceMethods.useFetch({
      callback: useCallback(async queryParams => await api.all({
        ...queryParams,
        'ids[]': ids
      }), [ids]),
      setLoading,
      paginationResourceField: 'claim_groups'
    });
    return [claimGroups, setClaimGroups, fetchClaimGroups, pages];
  }
};