import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback, useState } from 'react';

const api = buildService('/lists/users', { only: ['all'] })

const useFetchUsersByType  = ({ userType, setLoading }) => {
  const [users, setUsers, fetchUsers] = serviceMethods.useFetch({
    callback: useCallback(async () => {
      console.log('in callback', userType)
      return (await api.all({ type: userType })).users
    }, [userType]),
    setLoading
  })

  return [users, setUsers, fetchUsers]
}

export const userListService = {
  all: (type) => {
    return fetch(`/api/lists/users/?type=${type}`, request.get);
  },
  useFetchUsersByType
};
