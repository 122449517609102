export default function sortObjectsByProperty(arr, property) {
  return arr.sort((a, b) => {
    const valueA = a[property]?.toLowerCase();
    const valueB = b[property]?.toLowerCase();
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
}